import { Button } from "@mui/material";
import styled from "styled-components";
import Navbar from "../components/navbar/Navbar";

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import GroupIcon from "@mui/icons-material/Group";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import CreateIcon from "@mui/icons-material/Create";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import SettingsSystemDaydreamIcon from "@mui/icons-material/SettingsSystemDaydream";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import SettingsIcon from '@mui/icons-material/Settings';

import { HashLink as Link } from "react-router-hash-link";


const Home: React.FC = () => {
  return (
    <MainContainer className="page-with-nav">
      <Navbar />
      <ButtonsContainer>
        <Link to="/#step6">
          <Button variant="outlined" startIcon={<SettingsSystemDaydreamIcon />}>
            Ecossistema
          </Button>
        </Link>
        <Link to="/#step4">
          <Button variant="outlined" startIcon={<SubtitlesIcon />}>
            Acessos
          </Button>
        </Link>
        <Link to="/#step5">
          <Button variant="outlined" startIcon={<GroupIcon />}>
            Time
          </Button>
        </Link>
        <Link to="/#step2">
          <Button variant="outlined" startIcon={<FontDownloadIcon />}>
            Baixar fontes
          </Button>
        </Link>
        <Link to="/#step3">
          <Button variant="outlined" startIcon={<CreateIcon />}>
            Criar assinatura
          </Button>
        </Link>
        <Link to="/#step7">
          <Button variant="outlined" startIcon={<DoNotDisturbIcon />}>
            Offlimits
          </Button>
        </Link>
        <Link to="/#step8">
          <Button variant="outlined" startIcon={<SettingsIcon />}>
            Instalação do Yuri
          </Button>
        </Link>
        <Link to="/#step9">
          <Button variant="outlined" startIcon={<SystemUpdateAltIcon />}>
            Yuri
          </Button>
        </Link>
        <Link to="/#step10">
          <Button variant="outlined" startIcon={<PrivacyTipIcon />}>
           Privacidade
          </Button>
        </Link>
        <Link to="/#step11">
          <Button variant="outlined" startIcon={<LocalFireDepartmentIcon />}>
            Rituais
          </Button>
        </Link>
      
      </ButtonsContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 80%;
  flex-wrap: wrap;
  justify-content: center;
`

export default Home;
