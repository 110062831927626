import styled from "styled-components";
import SignatureGeneratorCard from "../../signatureGenerator/SignatureGeneratorCard";

import data from '../../../data.json';
import NextButton from "../NextButton";
import FormatText from "../../FormatText";


const Step3: React.FC = () => {

  return (
    <MainContainer id="step3">
      <h1>{data.passo_a_passo.assinatura.titulo}</h1>
      <h5>{FormatText(data.passo_a_passo.assinatura.texto)}</h5>
      <SignatureGeneratorCard />
      <NextButton toStep={4}/>

    </MainContainer>
  );
};

const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 15px;
  padding: 0px 50px;
  text-align: center;
`;


export default Step3;
