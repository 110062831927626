import amarelo from "../../images/signature/logos/amarelo.png";
import azul from "../../images/signature/logos/azul.png";
import laranja from "../../images/signature/logos/laranja.png";
import preto from "../../images/signature/logos/preto.png";
import rosa from "../../images/signature/logos/rosa.png";
import salmao from "../../images/signature/logos/salmao.png";
import verde from "../../images/signature/logos/verde.png";
import violeta from "../../images/signature/logos/violeta.png";

import logoLinkedin from "../../images/signature/linkedin.png";
import logoInstagram from "../../images/signature/instagram.png";

import styled from "styled-components";

interface Props {
  color: string;
  name: string;
  office: string;
  number: string;
}

const Signature:React.FC<Props> = ({ color, name, office, number }) => {
  const setColor = () => {
    switch (color) {
      case "azul":
        return azul;
      case "amarelo":
        return amarelo;
      case "laranja":
        return laranja;
      case "preto":
        return preto;
      case "rosa":
        return rosa;
      case "salmao":
        return salmao;
      case "verde":
        return verde;
      case "violeta":
        return violeta;
      default:
        return preto;
    }
  };

  return (
    <Main>
      <div id="signature">
        <table>
          <tr>
            <th>
              <img alt='' style={{ height: "98px" }} src={setColor()} />
            </th>
            <td>
              <b>{name || "Nicolas"}</b>
              <br />
              {office || "Desenvolvedor Júnior"}
              <br />
              {number || "+55 31 986785819"}
              <br />
              <a rel='noreferrer' href="https://www.jornadayu.com/" target="_blank">
                jornadayu.com
              </a>
              <br />
              <a
                rel='noreferrer'
                href="https://www.linkedin.com/company/jornadayu/"
                target="_blank"
              >
                <img alt='' src={logoInstagram} />
              </a>{" "}
              <a rel='noreferrer' href="https://www.instagram.com/jornadayu/" target="_blank">
                <img alt='' src={logoLinkedin} />
              </a>
              <br />
            </td>
          </tr>
        </table>
      </div>
    </Main>
  );
};

const Main = styled.div`
  height: 150px;
  min-width: 300px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  a {
    color: #222;
  }
`;

export default Signature;
