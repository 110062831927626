import styled from "styled-components";
import { IconButton } from "@mui/material";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { preto } from "../../../utils/colors";
import { useHistory } from "react-router";
import data from '../../../data.json';
import FormatText from "../../FormatText";


const Step11: React.FC = () => {

  const history = useHistory();

  const doneStepper = () => {
    history.push('home');
    localStorage.setItem('knownUser', 'true');
  }

  return(
    <MainContainer id='step11'>
      <h1>{data.passo_a_passo.encontros.titulo}</h1>
      <h5>{FormatText(data.passo_a_passo.encontros.texto)}</h5>
      <StyledIconButton onClick={doneStepper}>
        <DoneAllIcon sx={{fontSize: 60, color: preto}}/>
      </StyledIconButton>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 15px;
  padding: 0px 50px;
  text-align: center;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute !important;
  bottom: 10px;
`

export default Step11;