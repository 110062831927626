import styled from "styled-components";
import logoIMG from '../../images/logo/YU_Pos_Preto.png'


const Logo: React.FC = () => {
  return <MainContainer src={logoIMG} />;
};

const MainContainer = styled.img`
  position: absolute;
  top: 50px;
  width: 200px;
`;

export default Logo;
