import styled from 'styled-components'
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { preto } from '../../utils/colors';
import { useHistory } from 'react-router';

interface Props {
  toStep: number;
}

const NextButton: React.FC<Props> = ({toStep}) => {

  const history = useHistory();

  return (
    <MainContainer onClick={()=>localStorage.getItem('knownUser') && history.push('/home')} href={(!localStorage.getItem('knownUser') && `#step${toStep}`) || undefined}>
      <IconButton>
        <ArrowDownwardIcon sx={{fontSize: 60, color: preto}}/>
      </IconButton>
    </MainContainer>
  );
};

const MainContainer = styled.a`
  position: absolute;
  bottom: 10px;
`

export default NextButton;
