import styled from "styled-components";
import TextField from "@mui/material/TextField";

interface Props {
  setName: any;
  setOffice: any;
  setNumber: any;
}

const Form: React.FC<Props> = ({ setName, setOffice, setNumber }) => {
  const inputHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "name") {
      setName(event.target.value);
    }
    if (event.target.name === "office") {
      setOffice(event.target.value);
    }
    if (event.target.name === "number") {
      setNumber(event.target.value);
    }
  };

  return (
    <MainContainer>
      <TextField
        onChange={inputHandle}
        color="primary"
        name="name"
        placeholder="Nicolas"
        label="Nome"
        variant="filled"
      />
      <TextField
        onChange={inputHandle}
        name="office"
        placeholder="Desenvolvedor Júnior"
        label="Cargo"
        variant="filled"
      />
      <TextField
        onChange={inputHandle}
        name="number"
        label="Número"
        placeholder="+55 31 986785819"
        variant="filled"
      />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export default Form;
