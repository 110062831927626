import styled from "styled-components";
import Step1 from "../components/steps/boasVindas/Step1";
import Step2 from "../components/steps/fontes/Step2";
import Step3 from "../components/steps/assinatura/Step3";
import Step4 from "../components/steps/acessos/Step4";
import Step5 from "../components/steps/time/Step5";
import Step6 from "../components/steps/ecossistema/Step6";
import Step7 from "../components/steps/offlimits/step7";
import Step9 from "../components/steps/yuri/step9";
import Step10 from "../components/steps/privacidade/step10";
import Step11 from "../components/steps/rituais/Step11";
import Step8 from "../components/steps/beneficios/step8";
/*
Step1 = Boas vindas
Step2 = Fontes
Step3 = Assinatura
Step4 = Acessos
Step5 = Time
Step6 = Ecossistema
Step7 = Offlimits
Step8 = Intalação do Yuri
Step9 = Yuri
Step10 = Privacidade
Step11 = Rituais
*/

const Stepper: React.FC = () => {
  
  return (
    <MainContainer>
      {/* <Gradient /> */}
      <Step1 steps={11}/>
      <Step2 />
      <Step3 />
      <Step4 />
      <Step5 />
      <Step6 />
      <Step7 />
      <Step8 />
      <Step9 />
      <Step10 />
      <Step11 />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  scroll-behavior: smooth;
`;

export default Stepper;
