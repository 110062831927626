import { Button } from "@mui/material";
import styled from "styled-components";
import NextButton from "../NextButton";
import data from '../../../data.json';
import FormatText from "../../FormatText";

const Step2: React.FC = () => {

  const downloadFonts = () => {
    window.open('https://fonts.google.com/download?family=DM%20Sans');
    window.open('https://static1.squarespace.com/static/5e8cc7c970b91918875ca0d3/t/5e944716be74603c9208bbc7/1586775831436/integral-cf-bold.woff')
  }

  return (
    <MainContainer id="step2">
      <h1>{data.passo_a_passo.fontes.titulo}</h1>
      <h5>{FormatText(data.passo_a_passo.fontes.texto)}</h5>
      <Button variant='outlined' onClick={downloadFonts}>baixar</Button>
      <NextButton toStep={3}/>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 15px;
  padding: 0px 50px;
  text-align: center;
`;

export default Step2;
