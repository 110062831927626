import styled from "styled-components";
import NextButton from "../NextButton";
import data from '../../../data.json';
import Members from "../../Members";
import FormatText from "../../FormatText";

const Step5: React.FC = () => {
  return(
    <MainContainer id='step5'>
      <h1>{data.passo_a_passo.time.titulo}</h1>
      <h5>{FormatText(data.passo_a_passo.time.texto)}</h5>
      <Members />
      <NextButton toStep={6} />
    </MainContainer>
  );
}

const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 0px;
  padding: 0px 50px;
  text-align: center;
`;

export default Step5;