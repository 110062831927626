import styled from "styled-components";
import { yuGradient } from "../../../utils/colors";
import Logo from "../Logo";
import NextButton from "../NextButton";
import data from '../../../data.json';
import FormatText from "../../FormatText";

const Step1: React.FC<{steps: number}> = ({steps}) => {
  return (
    <MainContainer>
      <Logo />
      <Gradient steps={steps}/>
      <h1>{data.passo_a_passo.boas_vindas.titulo}</h1>
      <h2>{FormatText(data.passo_a_passo.boas_vindas.texto)}</h2>
      <NextButton toStep={2} />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0px 50px;
  text-align: center;
`;

const Gradient = styled.div<{steps : number}>`
  margin-top: ${props => (props.steps -1 ) + "00vh"};
  background: ${yuGradient};
  height: ${props => props.steps + "00%"};
  width: 10px;
  position: absolute;
  right: 20px;
`
export default Step1;
