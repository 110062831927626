import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser'; 

const FormatText = (text: string) => {
  return <MainContainer>{ReactHtmlParser (text)}</MainContainer>
};

const MainContainer = styled.div`
  text-align: center;
`

export default FormatText;
