import styled from "styled-components";
import NextButton from "../NextButton";
import data from '../../../data.json';
import FormatText from "../../FormatText";


const Step7: React.FC = () => {

  return(
    <MainContainer id='step7'>
      <h1>{data.passo_a_passo.offlimits.titulo}</h1>
      <h5>{FormatText(data.passo_a_passo.offlimits.texto)}</h5>
      <NextButton toStep={8} />
    </MainContainer>
  );
}

const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 15px;
  padding: 0px 50px;
  text-align: center;
`;

export default Step7;