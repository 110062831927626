import styled from "styled-components";
import {
  azul,
  preto,
  amarelo,
  rosa,
  violeta,
  verde,
  salmao,
  laranja,
} from "../../utils/colors";

interface Props {
  setColor: any;
}

const ColorButtons:React.FC<Props> = ({ setColor }) => {
  return (
    <Main>
      Selecione sua cor favorita
      <Buttons>
      <Button
        onClick={() => {
          setColor("violeta");
        }}
        color="violeta"
      />
      <Button
        onClick={() => {
          setColor("rosa");
        }}
        color="rosa"
      />
      <Button
        onClick={() => {
          setColor("salmao");
        }}
        color="salmao"
      />
      <Button
        onClick={() => {
          setColor("verde");
        }}
        color="verde"
      />
      <Button
        onClick={() => {
          setColor("azul");
        }}
        color="azul"
      />
      <Button
        onClick={() => {
          setColor("laranja");
        }}
        color="laranja"
      />
      <Button
        onClick={() => {
          setColor("amarelo");
        }}
        color="amarelo"
      />
      <Button
        onClick={() => {
          setColor("preto");
        }}
        color="preto"
      />
      </Buttons>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-family: 'DM Sans';
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 40px;
`;

const Button = styled.button`
  border: none;
  border-radius: 50px;
  height: 25px;
  width: 25px;
  background-color: ${(props) => {
    switch (props.color) {
      case "violeta":
        return violeta;
      case "rosa":
        return rosa;
      case "azul":
        return azul;
      case "salmao":
        return salmao;
      case "amarelo":
        return amarelo;
      case "verde":
        return verde;
      case "preto":
        return preto;
      case "laranja":
        return laranja;
    }
  }};
  transition: 100ms;
  :hover{
    cursor: pointer;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.5);
    width: 30px;
    height: 30px;
  }
  :active{
    box-shadow: none;
  }
`;

export default ColorButtons;
