import styled from "styled-components";
import NextButton from "../NextButton";
import data from '../../../data.json';
import FormatText from "../../FormatText";
import { Button } from "@mui/material";


const Step8: React.FC = () => {

  return(
    <MainContainer id='step8'>
      <h1>{data.passo_a_passo.instalacaoYuri.titulo}</h1>
      <h5>{FormatText(data.passo_a_passo.instalacaoYuri.texto)}</h5>
      <Button href={data.passo_a_passo.instalacaoYuri.link} target='_blank' variant='outlined'>Ir para o guia</Button>
      <NextButton toStep={9} />
    </MainContainer>
  );
}

const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 15px;
  padding: 0px 50px;
  text-align: center;
`;

export default Step8;