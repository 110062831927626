import styled from "styled-components";
import NextButton from "../NextButton";
import data from '../../../data.json';
import FormatText from "../../FormatText";


const Step10: React.FC = () => {

  return(
    <MainContainer id='step10'>
      <h1>{data.passo_a_passo.privacidade.titulo}</h1>
      <h5>{FormatText(data.passo_a_passo.privacidade.texto)}</h5>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${data.passo_a_passo.privacidade.id_video}`}
        frameBorder="0"
        allowFullScreen
        title="Apresentação Yuri"
      />
      <NextButton toStep={11} />
    </MainContainer>
  );
}

const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 15px;
  padding: 0px 50px;
  text-align: center;
`;

export default Step10;