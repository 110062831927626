import styled from "styled-components";
import NextButton from "../NextButton";
import data from '../../../data.json';
import Accesses from "../../Accesses";
import FormatText from "../../FormatText";


const Step4: React.FC = () => {
  return(
    <MainContainer id='step4'>
      <h1>{data.passo_a_passo.acessos.titulo}</h1>
      <h5>{FormatText(data.passo_a_passo.acessos.texto)}</h5>
      <Accesses />
      <NextButton toStep={5} />
    </MainContainer>
  );
}

const MainContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  gap: 15px;
  padding: 0px 50px;
  text-align: center;
`;

export default Step4;