export const violeta = "#D92ECB";
export const preto = "#222";
export const rosa = "#FE3D9C";
export const amarelo = "#FEFE3E";
export const salmao = "#FF746C";
export const laranja = "#FFA032";
export const azul = "#00C9FB";
export const verde = "#00F92C";
export const yuGradient = `linear-gradient(
  0deg,
  rgba(255, 102, 102, 1) 0%,
  rgba(255, 153, 0, 1) 17%,
  rgba(255, 255, 0, 1) 34%,
  rgba(0, 255, 0, 1) 50%,
  rgba(0, 204, 255, 1) 69%,
  rgba(204, 0, 204, 1) 85%,
  rgba(255, 0, 153, 1) 100%
)`;