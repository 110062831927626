import styled from "styled-components";
import photos from "../images/teamPhotos/index";

const Members = () => {
  return (
    <MainContainer>
      {photos.map((photo, i) => (
        <Person key={i}>
          <PersonPhoto key={i} src={photo} alt="" />
          {photo
            .replace("/teamPhotos/", "")
            .replace(".png", "")
            .replace(".jpg", "")
            .replace(".jpeg", "")}
        </Person>
      ))}
    </MainContainer>
  );
};
const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 50px;
  gap: 20px;
`;

const Person = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PersonPhoto = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 10%;
`;
export default Members;
