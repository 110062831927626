import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Brandbook from "./pages/Brandbook";
import History from "./pages/History";
import Home from "./pages/Home";
import Stepper from "./pages/Stepper";

const Routes: React.FC = () => {
  return(
    <Router>
      <Switch>
        <Route path='/' exact>
          <Stepper />
        </Route>
        <Route path='/home' exact>
          <Home />
        </Route>
        <Route path='/brandbook' exact>
          <Brandbook />
        </Route>
        <Route path='/history' exact>
          <History />
        </Route>
      </Switch>
    </Router>
  )
}
export default Routes;