import { Button } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import ColorButtons from './ColorButtons';
import Form from './Form';
import Signature from './Signature';
import data from '../../data.json';

const SignatureGeneratorCard:React.FC = () => {
  const [color, setColor] = useState("");
  const [name, setName] = useState("");
  const [office, setOffice] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("Copiar assinatura");

  const copyElement = () => {
    window.getSelection()!.removeAllRanges();
    let range = document.createRange();
    range.selectNode(document.getElementById("signature")!);
    window.getSelection()!.addRange(range);
    document.execCommand("copy");
    window.getSelection()!.removeAllRanges();
    setMessage("Assinatura copiada!");
    setTimeout(() => {
      setMessage("Copiar assinatura");
    }, 3000);
  }

  return (
    <>
    <InputCard>
      <Form
        setName={setName}
        setOffice={setOffice}
        setNumber={setNumber}
      />
      <ColorButtons setColor={setColor} />
      <Button variant='outlined' onClick={copyElement}>{message}</Button>
    </InputCard>
    <h3>Assinatura</h3>
    <OutputCard>
      <Signature name={name} color={color} office={office} number={number} />
    </OutputCard>
    {data.passo_a_passo.assinatura.disclaimer}
    </>
  );
}

const InputCard = styled.div`
  display:flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0 1.5px 3.6px rgba(0, 0, 0, 0.042),
    0 4.3px 10px rgba(0, 0, 0, 0.06), 0 10.3px 24.1px rgba(0, 0, 0, 0.078),
    0 34px 80px rgba(0, 0, 0, 0.12);
  padding: 50px;
  align-items: center;
`;
const OutputCard = styled.div`
  display:flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: 0 1.5px 3.6px rgba(0, 0, 0, 0.042),
    0 4.3px 10px rgba(0, 0, 0, 0.06), 0 10.3px 24.1px rgba(0, 0, 0, 0.078),
    0 34px 80px rgba(0, 0, 0, 0.12);
  align-items: center;
`;

export default SignatureGeneratorCard;