import Routes from "./Routes";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "@mui/material/styles";
import { createGlobalStyle } from "styled-components";
import { azul } from "./utils/colors";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #222;
    font-family: 'DM Sans';
  }
  h1{
    font-size: 25px;
    font-family: 'Integral CF Bold';
    margin: 0px;
  }
  h5{
    margin: 0px;
  }
  .pageWithNav {
    padding-top: 66px;
    height: calc(100vh - 66px);
  }
  a{
    color: ${azul};
  }

`;

const theme = createTheme({
  palette: {
    primary: {
      main: "#222",
    },
    secondary: {
      main: "#434242",
    },
  },
});

function App() {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </>
  );
}

export default App;
