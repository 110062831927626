import { AppBar, Drawer, IconButton, Toolbar } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import logoIMG from "../../images/logo/YU_Neg_Branco.png";
import Menu from "./Menu";

const Navbar: React.FC = () => {
  
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  
  return (
    <>
      <Drawer
        anchor='left'
        open={openMenu}
        onClose={()=>setOpenMenu(false)}
      >
        <Menu closeMenu={()=>setOpenMenu(false)}/>
      </Drawer>
      <AppBar color='secondary'>
        <Toolbar>
          <IconButton onClick={()=>history.push('/home')}>
            <Logo src={logoIMG} />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

const Logo = styled.img`
  height: 50px;
`;

export default Navbar;
