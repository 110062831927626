import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import styled from "styled-components";
import logoIMG from "../../images/logo/YU_Pos_Preto.png";
import { useHistory } from "react-router";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import BookIcon from '@mui/icons-material/Book';
import GroupIcon from '@mui/icons-material/Group';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import CreateIcon from '@mui/icons-material/Create';

const Menu: React.FC<{closeMenu: any}> = ({closeMenu}) => {

  const history = useHistory();

  return (
    <>
    <MainContainer>
      <List>
      <ListItem button onClick={()=>{history.push('/home')}}>
          <Logo src={logoIMG} />
        </ListItem>
        <Divider />
        <ListItem button onClick={()=>{history.push('/team')}}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Time" />
        </ListItem>
        <ListItem button onClick={()=>{history.push('/brandbook')}}>
          <ListItemIcon>
            <BookIcon />
          </ListItemIcon>
          <ListItemText primary="Brandbook" />
        </ListItem>
        <ListItem button onClick={()=>{history.push('/history')}}>
          <ListItemIcon>
            <HistoryEduIcon />
          </ListItemIcon>
          <ListItemText primary="Nossa história" />
        </ListItem>
        <Divider />
        <ListItem button onClick={()=>{history.push('/history')}}>
          <ListItemIcon>
            <FontDownloadIcon />
          </ListItemIcon>
          <ListItemText primary="Baixar fontes" />
        </ListItem>
        <ListItem button onClick={()=>{history.push('/signature')}}>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText primary="Gerar assinatura" />
        </ListItem>
      </List>
    </MainContainer>
  </>
  );
};

const MainContainer = styled.div`
  font-family: "DM Sans", sans-serif;
  width: 250px;
`;

const Logo = styled.img`
  height: 50px;
`;

export default Menu;
