import Navbar from "../components/navbar/Navbar";

const History: React.FC = () => {
  return (
    <div className='pageWithNav'>
      <Navbar />
      <h1>History</h1>
    </div>
  );
};

export default History;
