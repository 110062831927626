import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
} from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import data from "../data.json";
import FormatText from "./FormatText";

const Accesses: React.FC = () => {
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalText, setModalText] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = (name: string, text: string) => {
    setShowModal(true);
    setModalTitle(name);
    setModalText(text);
  };

  return (
    <MainContainer>
      <StyledModal open={showModal} onClose={() => setShowModal(false)}>
        <ModalContainer>
          <StyledModalTitle>{modalTitle}</StyledModalTitle>
          {FormatText(modalText)}
        </ModalContainer>
      </StyledModal>
      <List>
        {data.passo_a_passo.acessos.lista.map(({ nome, texto }) => (
          <ListItem divider key={nome} secondaryAction={<Checkbox edge="end" />}>
            <ListItemButton
              role={undefined}
              onClick={() => openModal(nome, texto)}
              dense
            >
              <ListItemText primary={nome} />
            </ListItemButton>
          </ListItem>
          // <div>
          //   <Checkbox />
          //   <Button variant='outlined' onClick={() => openModal(nome, texto)}>{nome}</Button>
          // </div>
        ))}
      </List>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledModalTitle = styled.h3``;

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  padding: 0px 50px 50px 50px;
  border-radius: 3%;
`;

export default Accesses;
