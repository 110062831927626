import Navbar from "../components/navbar/Navbar";

const Brandbook: React.FC = () => {
  return (
    <div className='pageWithNav'>
      <Navbar />
      <h1>Brandbook</h1>
    </div>
  );
};

export default Brandbook;
